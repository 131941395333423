import React, { useCallback, useEffect, useState, useRef } from "react";
import Sidebar from "./sidebar";
import Header from "./header";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';


import {
  DocumentEditorContainerComponent, CustomToolbarItemModel,
  Toolbar, ClickEventArgs
} from "@syncfusion/ej2-react-documenteditor";
import { useSearchParams } from "react-router-dom";

const BASE_URL = "https://dev.simplefirm.com";
// const BASE_URL = "http://127.0.0.1:8000";


DocumentEditorContainerComponent.Inject(Toolbar);

const WordProcessor = () => {
  let container = useRef(null);
  const [searchParams] = useSearchParams();
  const docId = searchParams.get("docId");
  const userId = searchParams.get("userId");
  const type = searchParams.get("type");
  const dynamic_template_id = searchParams.get("dynamic_template_id")
  const draft_id = searchParams.get("draftId") || ''

  const [state, setState] = useState({
    filename:'',
    updatedFileName:'',
    show:false,
    loading: true,
    error: false,
    status:'',
    draftId:''
    
  });
  const loadFile = useCallback(
    (file) => {
      const ajax = new XMLHttpRequest();
      ajax.open("POST", container.current?.serviceUrl + "Import", true);
      ajax.onreadystatechange = () => {
        if (ajax.readyState === 4) {
          if (ajax.status === 200 || ajax.status === 304) {
            //Open SFDT text in Document Editor
            setState((prev) => ({
              ...prev,
              loading: false,
            }));
            container.current?.documentEditor.open(ajax.responseText);
          } else {
            setState((prev) => ({
              ...prev,
              error: true,
              loading: false,
            }));
          }
        }
      };
      let formData = new FormData();
      formData.append("files", file);
      //Send the selected file to web api for converting it into sfdt.
      ajax.send(formData);
    },
    [container]
  );

  const getDocument = useCallback(async () => {
    try {

      let documentRes = await fetch(BASE_URL + "/30/ListDoc/?doc_id=" + docId, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      documentRes = await documentRes.json();
      setState(prevState => ({ ...prevState, filename: documentRes?.data?.file_name,updatedFileName:documentRes?.data?.file_name,draftId:draft_id }));

      

      let response = await fetch(documentRes?.data?.upload, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      response = await response.blob();
      loadFile(response);

      if(dynamic_template_id){
        if(type == "Draft"){
          let templateRes = await fetch(BASE_URL + "/30/getTemplateData/?template_id=" + dynamic_template_id + "&draft_id=" + draft_id, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          });
          templateRes = await templateRes.json();
          if(draft_id){
            setState(prevState => ({ ...prevState, filename: `${templateRes?.name}`,updatedFileName:`${templateRes?.name}`,draftId:draft_id}));  
          }
          else{
            setState(prevState => ({ ...prevState, filename: `${templateRes?.name} Draft ${templateRes?.count + 1}`,updatedFileName:`${templateRes?.name} Draft ${templateRes?.count + 1}`,draftId:draft_id})); 
          }
        
        }
        else{
          let templateRes = await fetch(BASE_URL + "/30/getTemplateData/?template_id=" + dynamic_template_id + "&draft_id=" + draft_id, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          });
          templateRes = await templateRes.json();
          setState(prevState => ({ ...prevState, filename: `${templateRes?.name}`,updatedFileName:`${templateRes?.name}`}));
  
        }

      }
    } catch (error) {
      setState((prev) => ({
        ...prev,
        error: true,
        loading: false,
      }));
    }
  }, [docId, loadFile]);


  const saveDocument = async () => {
    if(type == "Draft"){
      try {
        const content = await container.current?.documentEditor.saveAsBlob("Docx");
        const formData = new FormData();
        formData.append("file", content);
        formData.append("id", docId);
        formData.append("user_id", userId);
        formData.append("dynamic_template_id",dynamic_template_id)
        formData.append("file_name",state.updatedFileName)

        if(state.draftId){
          formData.append("draft_id",state.draftId)
        }

  
        let response = await fetch(BASE_URL + "/30/updateDraftDoc/", {
          method: "POST",
          body: formData,
        });

        const responseData = await response.json(); // Parse response JSON
        console.log(responseData); // Log the entire response for debugging
        
        const draftId = responseData.draft_id;
        console.log("Draft ID:", draftId);
        setState(prevState => ({ ...prevState, draftId: draftId  }));

      } catch (error) {
        console.error("Error saving document:", error);
      }

    }
    else{
      try {
        const content = await container.current?.documentEditor.saveAsBlob("Docx");
        const formData = new FormData();
        formData.append("file", content);
        formData.append("id", docId);
        formData.append("user_id", userId);
        formData.append("file_name",state.filename)
        formData.append("dynamic_template_id",dynamic_template_id)



  
        let response = await fetch(BASE_URL + "/30/updateWordProcessorDoc/", {
          method: "POST",
          body: formData,
        });
        console.log(response);
      } catch (error) {
        console.error("Error saving document:", error);
      }

    }
    setState(prevState => ({ ...prevState, status: `${state.updatedFileName} saved` }));
    showModal()
   
  };

  const deleteDocument = async () => {
    save();

      try {
        const formData = new FormData();
        formData.append("draft_id", state.draftId);
        let response = await fetch(BASE_URL + "/30/deleteDraftDoc/", {
          method: "POST",
          body: formData,
        });
        console.log(response);
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    
    
    setState(prevState => ({ ...prevState, status: `${state.filename} deleted` }));
    showModal()
   
  };

  const onToolbarClick = (args: ClickEventArgs): void => {
    switch (args.item.id) {
      case "download":
        save();
        break;
      case "save":
        saveDocument()
        break;
      case "delete":
        deleteDocument();
        break;
      default:
        break;
    }
  };

  let saveItem: CustomToolbarItemModel = {
    prefixIcon: "e-de-ctnr-lock",
    tooltipText: "Save",
    text: "Save",
    id: "save",
  };

  let deleteItem: CustomToolbarItemModel = {
    prefixIcon: "e-de-ctnr-close",
    tooltipText: "Delete",
    text: "Delete",
    id: "delete",
  };

  let downloadItem: CustomToolbarItemModel = {
    prefixIcon: "e-de-ctnr-download",
    tooltipText: "Download",
    text: "Download",
    id: "download",
  };

  if(state.draftId && type){
    var items = ['New', 'Open', 'Separator', 'Undo', 'Redo', 'Separator', 'Image', 'Table', 'Hyperlink', 'Bookmark', 'TableOfContents', 'Separator', 'Header', 'Footer', 'PageSetup', 'PageNumber', 'Break', 'InsertFootnote', 'InsertEndnote', 'Separator', 'Find', 'Separator', 'Comments', 'TrackChanges', 'Separator', 'LocalClipboard', 'RestrictEditing', 'Separator', 'FormFields', 'UpdateFields', saveItem, deleteItem, downloadItem];

  }
  else{
    var items = ['New', 'Open', 'Separator', 'Undo', 'Redo', 'Separator', 'Image', 'Table', 'Hyperlink', 'Bookmark', 'TableOfContents', 'Separator', 'Header', 'Footer', 'PageSetup', 'PageNumber', 'Break', 'InsertFootnote', 'InsertEndnote', 'Separator', 'Find', 'Separator', 'Comments', 'TrackChanges', 'Separator', 'LocalClipboard', 'RestrictEditing', 'Separator', 'FormFields', 'UpdateFields', saveItem, downloadItem];

  }

  useEffect(() => {
    if (container.current) {
      console.log("name");
      getDocument();
    }
  }, [getDocument]);

  if (!docId || state.error) {
    return (
      <div className="doc-error">
        <p>Document not found!</p>
      </div>
    );
  }

  const save = () => {
    // Download the document in sfdt format.
    container.current.documentEditor.save("sample", "Docx");
  };

  const updateFileName = async () =>{
    setState(prevState => ({
      ...prevState,
      filename: state.updatedFileName
  }));
   saveDocument()
    
};
  const closeModal = () => {
    setState(prevState => ({ ...prevState, show: false }));
  };
  // const closeModal2 = () => {
  //   setState(prevState => ({ ...prevState, show2: false }));
  // };

  const showModal = () => {
    setState(prevState => ({ ...prevState, show: true }));

  };


  // const handleFileNameChange = (event) => {
  //   const { value } = event.target;
  //   setState(prevState => ({ ...prevState, filename: value }));
  // };

  // const handleSaveModal = () => {
  //   setState(prevState => ({ ...prevState, showFileName: state.filename }));
  //   saveDocument();
  //   closeModal();

  // };
  return (
    <div className="page-wrapper">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="page-container">
        <div className="head-container">
          <div className="header">
            <Header />
          </div>
        </div>
       
          
			
        <div className="page-wrapper-inner">
          
          
                <div className="action-bar client-BarAlign main-action-bar  d-flex m-b-5 index-100 " >
                    <span className="page-icon"><img className="translate-note-icon" src="https://simplefirm-bucket.s3.amazonaws.com/static/BP_resources/images/icon/settings-logo-icon.svg"></img></span>
                    <div className="text-wrapper text-white d-flex align-items-center p-l-5">
                        <h2 className="text-white">{state.filename}</h2>
                    </div>
                    {type?(<div className="text-wrapper text-white d-flex align-items-center ml-auto m-r-630">  
                    <Form.Control type="text" placeholder="Enter File Name"     value={state.updatedFileName || ''} style={{width:372}}  // Ensure that the value is defined or set it to an empty string
       onChange={(e) => setState(prevState => ({ ...prevState, updatedFileName: e.target.value }))}  className="form-control height-25"
/>
                  
                    <button type="button" className="btn btn-primary font-weight-bold ml-2" onClick={updateFileName}>
                          Save Draft
                        </button>                    
                </div>):null}

                </div>
         
        
          <div
            style={{
              opacity: state.loading ? 0 : 1,
              pointerEvent: state.loading ? "none" : "auto",
              width: "100%",
            }}
          >



              <DocumentEditorContainerComponent
              id="container"
              height="100vh"
              ref={container}
              style={{ height: "92vh", paddingTop: "92px" }}
              serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
              toolbarClick={onToolbarClick} // Pass the function directly here
              enableToolbar={true}
              toolbarItems={items}
            />
      {/* <Modal show={state.show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Rename File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
      <Form.Group className="mb-3">
        <Form.Label>File Name</Form.Label>
        <Form.Control type="text" placeholder="Enter File Name" onChange={handleFileNameChange}/>
      </Form.Group>
      </Form>

        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={closeModal} className="mr-auto">
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveModal} >
            Save 
          </Button>
        </Modal.Footer>
      </Modal>
*/}
      <Modal show={state.show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{state.status}</Modal.Title>
        </Modal.Header>
      
        <Modal.Footer>
        <Button variant="secondary" onClick={closeModal} className="mx-auto">
            Dismiss
          </Button>
       
        </Modal.Footer>
      </Modal> 
          </div>
        </div>
      </div>

      {state.loading ? (
        <div
          style={{
            opacity: state.loading ? 1 : 0,
            pointerEvent: state.loading ? "auto" : "none",
          }}
        >
          <div className="loader-wrapper">
            <div className="loader"></div>
          </div>
        </div>
      ) : null}
    </div>
    
  );
};

export default WordProcessor;
