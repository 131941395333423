import React from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "./App.css";
import WordProcessor from "./components/word-processor";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <WordProcessor />,
      errorElement: <Navigate to="/" />,
    },
  ]);

  return <RouterProvider router={router} />;
}
export default App;
