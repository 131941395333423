import React from 'react';

// Functional Component
function Header() {
    const baseUrl = "https://simplefirm.com";
    return (<div>
        <header className="header-main-wrapper" id="client_header">
            <div className="logo" style={{zIndex: "99999 !important"}}>
                <img
                    className="logoss"
                    src="https://simplefirm-bucket.s3.amazonaws.com/static/img/logo-new.png"
                    alt=""
                />
                <div className="defaultImg">
                    <img src="https://simplefirm-bucket.s3.amazonaws.com/static/img/showbackground.png"
                         alt=""
                    />
                </div>
            </div>
            <div className="top-row d-flex flex-wrap">
                <div className="client-details-wrapper">
                    <div
                        className="client-details"
                        data-toggle="modal"
                        data-target="#prev-client-modal"
                    >
                        <div
                            className="right-skew"
                            id="client_panel_right_skew"
                            data-toggle="modal"
                            data-target="#prev-client-modal"
                        />
                        <div
                            className="left-skew"
                            id="client_panel_left_skew"
                            data-toggle="modal"
                            data-target="#prev-client-modal"
                        />
                        <div className="client-image position-relative">
                            <img
                                src="https://simplefirm-bucket.s3.amazonaws.com/static/page_photos/halle_berry.jpg.webp"
                                alt="John Doe"
                            />
                        </div>
                        <div
                            className="client-info position-relative z-index-1 d-flex-1"
                            id="client-info"
                        >
                            <div className="client-name d-flex align-items-center">
                                <a className="clientTabFont text-black d-block" href="#">
                                    Johnson<span className="text-dark-grey">,</span>
                                    Lakeasha
                                </a>
                            </div>
                            <div className="client-phone">
                                <a className="js-acc-btn text-black" href="#">
                                    {/* <span class="text-black font-weight-normal">5787898989</span> */}
                                </a>
                            </div>
                            <div className="d-flex align-items-center">
                                <a
                                    href="#"
                                    className="js-acc-btn text-black accident-type d-flex align-items-center"
                                >
                                    <span className="text-black font-weight-semibold">
                                        <span className="text-dark-grey">(</span>578
                                        <span className="text-dark-grey">)</span>
                                        789<span className="text-dark-grey">-</span>8989
                                    </span>
                                </a>
                                <a className="js-acc-btn text-black accident-date ml-auto" href="#">
                                    <span className="client-age d-flex ml-auto">
                                        <span className="text-grey">Age</span>
                                        <span className="text-black ml-1 font-weight-semibold">24</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <span
                            className="ic has-no-after ic-arrow text-primary d-flex align-items-center justify-content-center"
                            id="clients-dropdown"
                        >
                            <svg
                              width={34}
                              height={17}
                              viewBox="0 0 34 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                                <path d="M0.00683594 0H9.20684L17.0069 7.85384L24.807 0H34.007L17.0069 17L0.00683594 0Z" fill="currentColor"
                            />
                          </svg>
                        </span>
                    </div>
                </div>
                {" "}
                {/* <div
                    className="previous-client"
                    id="previous-client"
                >
                    <div
                        className="client-details d-flex align-items-center"
                    >
                        <div className="client-image position-relative">
                            <a href={`${baseUrl}/BP/switch_client/1/1/Accident/`}>
                                <img
                                    src="https://simplefirm-bucket.s3.amazonaws.com/static/img/avatar_new.svg"
                                    alt="John Doe"
                                />
                            </a>
                        </div>
                        <div className="client-info overflow-hidden col p-0">
                            <div className="client-name d-flex align-items-center">
                                <a
                                    href={`${baseUrl}/BP/switch_client/1/1/Accident/`}
                                    className="clientTabFont d-block"
                                >
                                    Khan<span>,</span> Farhan
                                </a>
                            </div>
                            <div className="client-phone">
                                <a
                                    href="/BP/switch_client/1/1/Accident/"
                                    className="js-acc-btn text-black"
                                >
                                </a>
                            </div>
                            <div className="d-flex align-items-center">
                                <a
                                    href="/BP/switch_client/1/1/Accident/"
                                    className="js-acc-btn text-black accident-type d-flex align-items-center"
                                >
                                    <span className="text-black font-weight-normal">
                                        <span className="text-dark-grey">(</span>999
                                        <span className="text-dark-grey">)</span>
                                        888<span className="text-dark-grey">-</span>7777
                                    </span>
                                </a>
                                <a
                                    href="/BP/switch_client/1/1/Accident/"
                                    className="js-acc-btn text-black accident-date ml-auto"
                                >
                                    <span className="client-age d-flex ml-auto">
                                        <span className="text-grey">Age</span>
                                        <span className="text-black ml-1 font-weight-semibold">24</span>
                                    </span>
                                </a>
                            </div>
                            <div className="position-relative">
                                <i className="fa fa-chevron-down" aria-hidden="true"/>
                            </div>
                        </div>
                    </div>
                    <div
                        className="client-details d-flex align-items-center"
                    >
                        <div className="client-image position-relative">
                            <a href="/BP/switch_client/2/2/Accident/">
                                <img
                                    src="https://simplefirm-bucket.s3.amazonaws.com/static/img/avatar_new.svg"
                                    alt="John Doe"
                                />
                            </a>
                        </div>
                        <div className="client-info overflow-hidden col p-0">
                            <div className="client-name d-flex align-items-center">
                                <a
                                    href="/BP/switch_client/2/2/Accident/"
                                    className="clientTabFont d-block"
                                >
                                    Silverthorne<span>,</span>{" "}
                                    Elizabeth
                                </a>
                            </div>
                            <div className="client-phone">
                                <a
                                    href="/BP/switch_client/2/2/Accident/"
                                    className="js-acc-btn text-black"
                                >
                                </a>
                            </div>
                            <div className="d-flex align-items-center">
                                <a
                                    href="/BP/switch_client/2/2/Accident/"
                                    className="js-acc-btn text-black accident-type d-flex align-items-center"
                                >
                                    <span className="text-black font-weight-normal">
                                        <span className="text-dark-grey">(</span>949
                                        <span className="text-dark-grey">)</span>
                                        344<span className="text-dark-grey">-</span>8223
                                    </span>
                                </a>
                                <a
                                    href="/BP/switch_client/2/2/Accident/"
                                    className="js-acc-btn text-black accident-date ml-auto"
                                >
                                    <span className="client-age d-flex ml-auto">
                                        <span className="text-grey">Age</span>
                                        <span className="text-black ml-1 font-weight-semibold">24</span>
                                    </span>
                                </a>
                            </div>
                            <div className="position-relative">
                                <i className="fa fa-chevron-down" aria-hidden="true"/>
                            </div>
                        </div>
                    </div>
                    <div
                        className="client-details d-flex align-items-center"
                    >
                        <div className="client-image position-relative">
                            <a href="/BP/switch_client/16/15/Accident/">
                                <img
                                    src="https://simplefirm-bucket.s3.amazonaws.com/static/img/avatar_new.svg"
                                    alt="John Doe"
                                />
                            </a>
                        </div>
                        <div className="client-info overflow-hidden col p-0">
                            <div className="client-name d-flex align-items-center">
                                <a
                                    href="/BP/switch_client/16/15/Accident/"
                                    className="clientTabFont d-block"
                                >
                                    Sevilla<span>,</span>{" "}
                                    Florinda
                                </a>
                            </div>
                            <div className="client-phone">
                                <a
                                    href="/BP/switch_client/16/15/Accident/"
                                    className="js-acc-btn text-black"
                                >
                                </a>
                            </div>
                            <div className="d-flex align-items-center">
                                <a
                                    href="/BP/switch_client/16/15/Accident/"
                                    className="js-acc-btn text-black accident-type d-flex align-items-center"
                                >
                                    <span className="text-black font-weight-normal">
                                        <span className="text-dark-grey">(</span>562
                                        <span className="text-dark-grey">)</span>
                                        922<span className="text-dark-grey">-</span>3406
                                    </span>
                                </a>
                                <a
                                    href="/BP/switch_client/16/15/Accident/"
                                    className="js-acc-btn text-black accident-date ml-auto"
                                >
                                    <span className="client-age d-flex ml-auto">
                                        <span className="text-grey">Age</span>
                                        <span className="text-black ml-1 font-weight-semibold">24</span>
                                    </span>
                                </a>
                            </div>
                            <div className="position-relative">
                                <i className="fa fa-chevron-down" aria-hidden="true"/>
                            </div>
                        </div>
                    </div>
                    <div
                        className="client-details d-flex align-items-center"
                    >
                        <div className="client-image position-relative">
                            <a href="/BP/switch_client/6/7/Accident/">
                                <img
                                    src="https://simplefirm-bucket.s3.amazonaws.com/static/img/avatar_new.svg"
                                    alt="John Doe"
                                />
                            </a>
                        </div>
                        <div className="client-info overflow-hidden col p-0">
                            <div className="client-name d-flex align-items-center">
                                <a
                                    href="/BP/switch_client/6/7/Accident/"
                                    className="clientTabFont d-block"
                                >
                                    O'Brien<span>,</span> John
                                </a>
                            </div>
                            <div className="client-phone">
                                <a
                                    href="/BP/switch_client/6/7/Accident/"
                                    className="js-acc-btn text-black"
                                >
                                </a>
                            </div>
                            <div className="d-flex align-items-center">
                                <a
                                    href="/BP/switch_client/6/7/Accident/"
                                    className="js-acc-btn text-black accident-type d-flex align-items-center"
                                >
                                    <span className="text-black font-weight-normal">
                                        <span className="text-dark-grey">(</span>999
                                        <span className="text-dark-grey">)</span>
                                        888<span className="text-dark-grey">-</span>7777
                                    </span>
                                </a>
                                <a
                                    href="/BP/switch_client/6/7/Accident/"
                                    className="js-acc-btn text-black accident-date ml-auto"
                                >
                                    <span className="client-age d-flex ml-auto">
                                        <span className="text-grey">Age</span>
                                        <span className="text-black ml-1 font-weight-semibold">24</span>
                                    </span>
                                </a>
                            </div>
                            <div className="position-relative">
                                <i className="fa fa-chevron-down" aria-hidden="true"/>
                            </div>
                        </div>
                    </div>
                    <div
                        className="client-details d-flex align-items-center"
                    >
                        <div className="client-image position-relative">
                            <a href="/BP/switch_client/4/5/Accident/">
                                <img
                                    src="https://simplefirm-bucket.s3.amazonaws.com/static/img/avatar_new.svg"
                                    alt="John Doe"
                                />
                            </a>
                        </div>
                        <div className="client-info overflow-hidden col p-0">
                            <div className="client-name d-flex align-items-center">
                                <a
                                    href="/BP/switch_client/4/5/Accident/"
                                    className="clientTabFont d-block"
                                >
                                    Hales<span>,</span> Alex
                                </a>
                            </div>
                            <div className="client-phone">
                                <a
                                    href="/BP/switch_client/4/5/Accident/"
                                    className="js-acc-btn text-black"
                                >
                                </a>
                            </div>
                            <div className="d-flex align-items-center">
                                <a
                                    href="/BP/switch_client/4/5/Accident/"
                                    className="js-acc-btn text-black accident-type d-flex align-items-center"
                                >
                                    <span className="text-black font-weight-normal">
                                        <span className="text-dark-grey">(</span>333
                                        <span className="text-dark-grey">)</span>
                                        222<span className="text-dark-grey">-</span>4444
                                    </span>
                                </a>
                                <a
                                    href="/BP/switch_client/4/5/Accident/"
                                    className="js-acc-btn text-black accident-date ml-auto"
                                >
                                    <span className="client-age d-flex ml-auto">
                                        <span className="text-grey">Age</span>
                                        <span className="text-black ml-1 font-weight-semibold">24</span>
                                    </span>
                                </a>
                            </div>
                            <div className="position-relative">
                                <i className="fa fa-chevron-down" aria-hidden="true"/>
                            </div>
                        </div>
                    </div>
                    <div
                        className="client-details d-flex align-items-center"
                    >
                        <div className="client-image position-relative">
                            <a href="/BP/switch_client/5/6/Accident/">
                                <img
                                    src="https://simplefirm-bucket.s3.amazonaws.com/static/img/avatar_new.svg"
                                    alt="John Doe"
                                />
                            </a>
                        </div>
                        <div className="client-info overflow-hidden col p-0">
                            <div className="client-name d-flex align-items-center">
                                <a
                                    href="/BP/switch_client/5/6/Accident/"
                                    className="clientTabFont d-block"
                                >
                                    Mackey<span>,</span> Raymond
                                </a>
                            </div>
                            <div className="client-phone">
                                <a
                                    href="/BP/switch_client/5/6/Accident/"
                                    className="js-acc-btn text-black"
                                >
                                </a>
                            </div>
                            <div className="d-flex align-items-center">
                                <a
                                    href="/BP/switch_client/5/6/Accident/"
                                    className="js-acc-btn text-black accident-type d-flex align-items-center"
                                >
                                    <span className="text-black font-weight-normal">
                                        <span className="text-dark-grey">(</span>949
                                        <span className="text-dark-grey">)</span>
                                        900<span className="text-dark-grey">-</span>6669
                                    </span>
                                </a>
                                <a
                                    href="/BP/switch_client/5/6/Accident/"
                                    className="js-acc-btn text-black accident-date ml-auto"
                                >
                                    <span className="client-age d-flex ml-auto">
                                        <span className="text-grey">Age</span>
                                        <span className="text-black ml-1 font-weight-semibold">24</span>
                                    </span>
                                </a>
                            </div>
                            <div className="position-relative">
                                <i className="fa fa-chevron-down" aria-hidden="true"/>
                            </div>
                        </div>
                    </div>
                    <div
                        className="client-details d-flex align-items-center"
                    >
                        <div className="client-image position-relative">
                            <a href="/BP/switch_client/20/19/Accident/">
                                <img
                                    src="https://simplefirm-bucket.s3.amazonaws.com/static/img/avatar_new.svg"
                                    alt="John Doe"
                                />
                            </a>
                        </div>
                        <div className="client-info overflow-hidden col p-0">
                            <div className="client-name d-flex align-items-center">
                                <a
                                    href="/BP/switch_client/20/19/Accident/"
                                    className="clientTabFont d-block"
                                >
                                    Patton<span>,</span> Zaneta
                                </a>
                            </div>
                            <div className="client-phone">
                                <a
                                    href="/BP/switch_client/20/19/Accident/"
                                    className="js-acc-btn text-black"
                                >
                                </a>
                            </div>
                            <div className="d-flex align-items-center">
                                <a
                                    href="/BP/switch_client/20/19/Accident/"
                                    className="js-acc-btn text-black accident-type d-flex align-items-center"
                                >
                                    <span className="text-black font-weight-normal">
                                        <span className="text-dark-grey">(</span>999
                                        <span className="text-dark-grey">)</span>
                                        444<span className="text-dark-grey">-</span>5555
                                    </span>
                                </a>
                                <a
                                    href="/BP/switch_client/20/19/Accident/"
                                    className="js-acc-btn text-black accident-date ml-auto"
                                >
                                  <span className="client-age d-flex ml-auto">
                                    <span className="text-grey">Age</span>
                                    <span className="text-black ml-1 font-weight-semibold">24</span>
                                  </span>
                                </a>
                            </div>
                            <div className="position-relative">
                                <i className="fa fa-chevron-down" aria-hidden="true"/>
                            </div>
                        </div>
                    </div>
                    <div
                        className="client-details d-flex align-items-center"
                    >
                        <div className="client-image position-relative">
                            <a href="/BP/switch_client/1/3/Accident/">
                                <img
                                    src="https://simplefirm-bucket.s3.amazonaws.com/static/img/avatar_new.svg"
                                    alt="John Doe"
                                />
                            </a>
                        </div>
                        <div className="client-info overflow-hidden col p-0">
                            <div className="client-name d-flex align-items-center">
                                <a
                                    href="/BP/switch_client/1/3/Accident/"
                                    className="clientTabFont d-block"
                                >
                                    Khan<span>,</span> Farhan
                                </a>
                            </div>
                            <div className="client-phone">
                                <a
                                    href="/BP/switch_client/1/3/Accident/"
                                    className="js-acc-btn text-black"
                                >
                                </a>
                            </div>
                            <div className="d-flex align-items-center">
                                <a
                                    href="/BP/switch_client/1/3/Accident/"
                                    className="js-acc-btn text-black accident-type d-flex align-items-center"
                                >
                                    <span className="text-black font-weight-normal">
                                        <span className="text-dark-grey">(</span>999
                                        <span className="text-dark-grey">)</span>
                                        888<span className="text-dark-grey">-</span>7777
                                    </span>
                                </a>
                                <a
                                    href="/BP/switch_client/1/3/Accident/"
                                    className="js-acc-btn text-black accident-date ml-auto"
                                >
                                  <span className="client-age d-flex ml-auto">
                                    <span className="text-grey">Age</span>
                                    <span className="text-black ml-1 font-weight-semibold">24</span>
                                  </span>
                                </a>
                            </div>
                            <div className="position-relative">
                                <i className="fa fa-chevron-down" aria-hidden="true"/>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="bar position-relative">
                    <div className="wrap stacked-icons">
                        <ul id="main-2">
                            <li>
                                <a href={`${baseUrl}/BP/37/3/4/`}>
                                    <div className="single-icon">
                                        <i className="ic ic-42 ic-home"/>
                                        <small>Home Page</small>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href={`${baseUrl}/BP/28/3/4/`}>
                                    <div className="single-icon">
                                        <i className="ic ic-42 ic-case-navigator"/>
                                        <small>Case Navigator</small>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href={`${baseUrl}/BP/3/3/4/`}>
                                    <div className="single-icon">
                                        <i className="ic ic-42 ic-worklist"/>
                                        <small>Work List</small>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href={`${baseUrl}/BP/31/3/4/`}>
                                    <div className="single-icon">
                                        <i className="ic ic-42 ic-calendar"/>
                                        <small>Calendar</small>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href={`${baseUrl}/BP/46/3/4/`}>
                                    <div className="single-icon">
                                        <i className="ic ic-42 ic-speedometer"/>
                                        <small>CheckLists</small>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href={`${baseUrl}/BP/32/3/4/`}>
                                    <div className="single-icon">
                                        <i className="ic ic-42 ic-chat-circle"/>
                                        <small>Chat</small>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href={`${baseUrl}/BP/33/3/4/`}>
                                    <div className="single-icon">
                                        <i className="ic ic-42 ic-flagged"/>
                                        <small>Flagged Cases</small>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href={`${baseUrl}/BP/51/3/4/`}>
                                    <div className="single-icon">
                                        <i className="ic ic-42 ic-reports-updated"/>
                                        <small>Reports</small>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href={`${baseUrl}/BP/47/3/4/`}>
                                    <div className="single-icon">
                                        <i className="ic ic-42 ic-directory"/>
                                        <small>Directory</small>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href={`${baseUrl}/BP/38/3/4/`}>
                                    <div className="single-icon">
                                        <i className="ic ic-42 ic-accounting"/>
                                        <small>Accounting</small>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <div className="single-icon">
                                        <i className="ic ic-42 ic-library"/>
                                        <small>Library</small>
                                    </div>
                                </a>
                            </li>
                            <li
                                className="more-2 hidden"
                                data-width={80}
                            >
                                <a href="#" className="more-button">
                                        <span className="ic ic-17 h-100 has-no-after text-white d-flex align-items-center justify-content-center">
                                            <svg
                                                width={17}
                                                height={50}
                                                viewBox="0 0 17 50"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                  d="M0 16H4.59998L8.5 19.95L12.4 16H17L8.5 24.55L0 16Z"
                                                  fill="currentColor"
                                              />
                                              <path
                                                  d="M0 25.4512H4.59998L8.5 29.4012L12.4 25.4512H17L8.5 34.0012L0 25.4512Z"
                                                  fill="currentColor"
                                              />
                                            </svg>
                                        </span>
                                </a>
                                <ul className="collapsible-ul">
                                    <li data-width={62}>
                                        <a href={`${baseUrl}/BP/40/3/4/`}>
                                            <div className="single-icon">
                                                <i className="ic ic-42 ic-copilot"/>
                                                <small>Co Pilot</small>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div
                    className="inbox-icon-wrap">
                    <a href={`${baseUrl}/BP/addNewCase/3/4/`}>
                        <div
                            className="inbox-icon inbox-icon-2 text-center"
                        >
                            <img
                                src="https://simplefirm-bucket.s3.amazonaws.com/static/BP_resources/images/icon/add-new-case-logo-icon.svg"
                                alt="John Doe"
                            />
                            <small className="d-block">Add New Case</small>
                        </div>
                    </a>
                </div>
                <div
                    className="inbox-icon-wrap">
                    <a
                        className="dropzone dz-clickable"
                        href={`${baseUrl}/BP/30/3/4/`}
                    >
                        <div className="inbox-icon text-center">
                            <img
                                src="https://simplefirm-bucket.s3.amazonaws.com/static/BP_resources/images/icon/inbox-logo-icon.svg"
                                alt="John Doe"
                            />
                            <small className="d-block">Inbox</small>
                        </div>
                    </a>
                </div>
                <form action={`${baseUrl}/BP/37/3/4/`} className="search-form" method="POST">
                    <input
                        type="hidden"
                        name="csrfmiddlewaretoken"
                        defaultValue="deNnsd2yynKStbz7RHkBzfr3aAQUyvrs97lrypUc6W5duJqZPPEko2bucMnPIMdY"
                    />
                    <input
                        type="button"
                        name="name"
                        id="search_header"
                        className="form-control w-100"
                        defaultValue="                "
                    />
                    <input type="text" name="check" defaultValue="False" hidden/>
                    <input
                        type="text"
                        name="search_category"
                        defaultValue="Client"
                        hidden
                    />
                    <div className="search-btn">
                        <input className="btn" type="submit" defaultValue="Search"/>
                        <div className="right-skew"></div>
                        <div className="left-skew"></div>
                    </div>
                    <div
                        className="right-skew"
                    />
                    <div
                        className="left-skew"
                    />
                </form>
            </div>
        </header>
    </div>);
}

export default Header;