import React from 'react';

// Functional Component
function Sidebar() {
    const baseUrl = "https://simplefirm.com";
    return (
        <div>
            <aside className="menu-sidebar d-none d-lg-block">
                <div className="menu-sidebar__content">
                    <nav className="navbar-sidebar position-relative hello">
                        <ul className="list-unstyled navbar__list">
                            <li className="Row hc mt-0">
                                <a href={`${baseUrl}/BP/2/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/case-icon-color_gIGzPMA.svg"/>
                                    Case
                                </a>
                                <div className="blueSpace">
                                    {" "}
                                </div>
                            </li>
                            <li
                                className="Row hc active">
                                <a href={`${baseUrl}/BP/4/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/client-icon-color_KismKtn.svg"/>
                                    Client
                                </a>
                                <div className="blueSpace">
                                    {" "}
                                </div>
                            </li>
                            <li
                                className="Row hc">
                                <a href={`${baseUrl}/BP/6/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/car-accident-icon-color_w060Q2v.svg"/>
                                    Accident
                                </a>
                                <div className="blueSpace">
                                    {" "}
                                </div>
                            </li>
                            <li
                                className="Row hc">
                                <a href={`${baseUrl}/BP/15/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/incident-folder-icon-color_ISjV4RF.svg"/>
                                    Reports
                                </a>
                                <div className="blueSpace">
                                    {" "}
                                </div>
                            </li>
                            <li
                                className="Row hc">
                                <a href={`${baseUrl}/BP/9/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/medical-treatment-icon-color_c8vboXY.svg"/>
                                    Treatment
                                </a>
                                <div className="blueSpace">
                                    {" "}
                                </div>
                            </li>
                            <li
                                className="Row hc">
                                <a href={`${baseUrl}/BP/8/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/injuries-icon-color_V7G2kdz.svg"/>
                                    Injury
                                </a>
                                <div className="blueSpace">
                                    {" "}
                                </div>
                            </li>
                            <li
                                className="Row hc">
                                <a href={`${baseUrl}/BP/3/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/to-do-icon-color_PyU3ldW.svg"/>
                                    To Do
                                </a>
                                <div className="blueSpace">
                                    {" "}
                                </div>
                            </li>
                            <li
                                className="Row hc">
                                <a href={`${baseUrl}/BP/5/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/defendants-icon-color_iTlLGPW.svg"/>
                                    Defendants
                                </a>
                                <div className="blueSpace">
                                    {" "}
                                </div>
                            </li>
                            <li
                                className="Row hc">
                                <a href={`${baseUrl}/BP/11/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/witnesses-icon-color_iYKDd0f.svg"/>
                                    Witnesses
                                </a>
                                <div className="blueSpace"></div>
                            </li>
                            <li
                                className="Row hc">
                                <a href={`${baseUrl}/BP/10/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/other-parties-icon-color_i60nUMt.svg"/>
                                    Parties
                                </a>
                                <div className="blueSpace"></div>
                            </li>
                            <li
                                className="Row hc">
                                <a href={`${baseUrl}/BP/12/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/insurance-icon-color_2pFtjE8.svg"/>
                                    Insurance
                                </a>
                                <div className="blueSpace"></div>
                            </li>
                            <li
                                className="Row hc">
                                <a href={`${baseUrl}/BP/42/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/incident-folder-icon-color_HJ6Dbke_esg0UEc.svg"/>
                                    Loans
                                </a>
                                <div className="blueSpace"></div>
                            </li>
                            <li
                                className="Row hc">
                                <a href={`${baseUrl}/BP/17/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/costs-icon-color_Iw0rjvm.svg"/>
                                    Costs
                                </a>
                                <div className="blueSpace"></div>
                            </li>
                            <li
                                className="Row hc">
                                <a href={`${baseUrl}/BP/20/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/settlement-icon-color_lfIgUlF.svg"/>
                                    Settle
                                </a>
                                <div className="blueSpace"></div>
                            </li>
                            <li
                                className="Row hc">
                                <a href={`${baseUrl}/BP/19/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/litigation-icon-color_5xPGzjY.svg"/>
                                    Litigation
                                </a>
                                <div className="blueSpace"></div>
                            </li>
                            <li
                                className="Row hc">
                                <a href={`${baseUrl}/BP/21/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/discovery-icon-color_97Wb0rd.svg"/>
                                    Discovery
                                </a>
                                <div className="blueSpace"></div>
                            </li>
                            <li
                                className="Row hc">
                                <a href={`${baseUrl}/BP/experts/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/experts-icon-color_10hcaau.svg"/>
                                    Experts
                                </a>
                                <div className="blueSpace"></div>
                            </li>
                            <li
                                className="Row hc">
                                <a href={`${baseUrl}/BP/7/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/photo-icon-color_2CyRX1a.svg"/>
                                    Photos
                                </a>
                                <div className="blueSpace"></div>
                            </li>
                            <li
                                className="Row hc">
                                <a href={`${baseUrl}/BP/16/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/documents-icon-color_b6TvcB7.svg"/>
                                    Docs
                                </a>
                                <div className="blueSpace"></div>
                            </li>
                            <li
                                className="Row hc">
                                <a href={`${baseUrl}/BP/23/3/4/`}>
                                    <img alt="" src="https://simplefirm-bucket.s3.amazonaws.com/static/images/notes-icon-color_J6snnHl.svg"/>
                                    Notes
                                </a>
                                <div className="blueSpace"></div>
                            </li>
                        </ul>
                        <ul className="list-unstyled navbar__list static-pages p-t-15 p-b-10">
                            <li className="Row hc">
                                <a href="#">
                                    <i className="ic ic-23 ic-help-2"/>
                                    Help
                                </a>
                                <div className="blueSpace"></div>
                            </li>
                            <li className="Row hc">
                                <a href={`${baseUrl}/BP/34/3/4/`}>
                                    <i className="ic ic-23 ic-settings-2"/>
                                    Settings
                                </a>
                                <div className="blueSpace"></div>
                            </li>
                            <li className="Row hc">
                                <a href={`${baseUrl}/BP/logoutPage`} className="has-no-after">
                                    <i className="ic ic-23 ic-logout2"/>
                                    Log Out
                                </a>
                                <div className="blueSpace"></div>
                            </li>
                        </ul>
                        <ul className="list-unstyled navbar__list user-information">
                            <li className="has-firm-user">
                                <span className="ic ic-avatar ic-29 has-avatar-icon has-cover-img"/>
                                <span className="text">Usama Nawaz</span>
                            </li>
                            <li className="text-lg">
                              <span className="d-flex-1">
                                <span className="date d-block">August 08, 2023</span>
                                <span className="time d-block">7:07 AM</span>
                              </span>
                            </li>
                            <li className="text-lg">
                                <span className="d-flex-1">154.80.47.194</span>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>

        </div>);
}

export default Sidebar;